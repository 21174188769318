
import React from "react";
import styled from 'styled-components'
import { Link } from "react-router-dom";



function BurgerMenu({ after }) {




  return (
    <Container>


      <li>
        <Link to="/"><li>HOME</li></Link>

      </li>
      <li>
        <Link to="/about"><li>ABOUT</li></Link>

      </li>
      {/* <li>
        <Link to="#">BLOGS </Link>

      </li> */}


      <li>
        <Link to="/gallery"><li>TATTOO GALLERY</li></Link>

      </li>
      <li>
        <Link to="/artis-service"><li>ARTIST GALLERY</li></Link>

      </li>
      <li>
        <Link to="/service"><li>FAQ</li></Link>

      </li>
      <li>
        <Link to="/book-now"><li style={{ color: 'green' }}>CHAT NOW</li></Link>

      </li>
    </Container>
  )
}

export default BurgerMenu

const Container = styled.div`
 height:100vh;
a{
  color: #AAA;
}

`