import React from 'react'
import styled from 'styled-components'
import img1 from '../../assets/artist/bob.jpg'
import Twitter from '../../assets/images/twitter.svg'
import Instagram from '../../assets/images/Instagram.svg'
import Youtube from '../../assets/images/Youtube.svg'
import HomeShow from '../Pricing/HomeShow'
import { Link } from 'react-router-dom'
import reactangle from '../../assets/images/KSHR.jpg'
import reactangle2 from '../../assets/images/warr.jpg'
import reactangle3 from '../../assets/images/wold.jpg'

function BobArtistCard() {
    return (
        <>
            <Conatiner>
                <LeftSide>
                    <RightImage>
                        <img src={img1} alt='' title='' />
                        <h1>Bob</h1>
                        <SocialLink>

                            <a target='blank' href=" https://twitter.com/linkosm_in"><li> <img src={Twitter} alt='Linkosm Twitter Logo' title='Linkosm Twitter Logo' /></li></a>
                            <a target='blank' href="https://www.instagram.com/linkosm_in/"><li> <img src={Instagram} alt='Linkosm Instagram Logo' title='Linkosm Instagram Logo' /></li></a>
                            <a target='blank' href="https://www.youtube.com/@linkosm"> <li> <img src={Youtube} alt='Linkosm YouTube Logo' title='Linkosm YouTube Logo' /></li></a>


                        </SocialLink>
                    </RightImage>
                </LeftSide>

                <RightSide>

                    <p> "Bob is a reserved artist who consistently channels his energy into his creative endeavors."
                    </p>
                    <br />
                    <p>"Bob had a passion for painting and sketching since childhood, and he actively participated in all the art events at school." "He knew from childhood that he wanted to be an artist. Over time, he came across information about tattoo art, and he delved into the exploration of this art form." </p>
                    <p>" He started working in a studio as an apprentice. In Bhopal Madhya Pradesh "</p>
                    <br />

                    <p>""Along with tattoos, he also had a keen interest in graffiti." Bob has now completed 10 years of tattooing and creating graffiti."</p>
                    <br />
                    <p>"He left his mark in many places with his graffiti and showcased his remarkable art in numerous cafes and restaurants."
                        "Now, Bob has been with Tattoo Trippers for quite some time, and he dedicates 100% of his artistry to it. His work and personality are highly appreciated by all the artists and his customers there."
                    </p>


                </RightSide>

            </Conatiner>

            <HomeShow
                img1={reactangle}
                img2={reactangle2}
                img3={reactangle3}
            />
            <Buttons>   <Link to="/gallery">VIEW GALLERY</Link></Buttons>

        </>
    )
}

export default BobArtistCard


const SocialLink = styled.div`
padding-left:0px;
display:flex;
flex-wrap:no-wrap;

li{
    img{
        width:55px;
        height:55px;
       padding:15px;
       cursor: pointer;
      
    }
    &hover{
        color:#fff;
       }
}

@media screen and (max-width: 768px)_
li{
    a{
        margin-top:30px;
    }
}
`

const Conatiner = styled.div`
display:flex;

@media screen and (max-width: 768px){
    flex-wrap:wrap;
}
p{
    color:#000;
    font-size:18px;
}
`

const RightSide = styled.div`
padding-top:80px;

h1{
    padding-left:10px;
    color:#000;
    Font-size:40px;
}
@media screen and (max-width: 768px){
    padding-top:10px;
    padding:10px;
}
`
const LeftSide = styled.div`


`


const RightImage = styled.div`
width:100%;
padding:30px;
outline: none;
border-radius: 50%;
margin-top:110px;
img{
  width: 300px;
  height:300px;
  border-radius: 50%;
}
@media screen and (max-width: 768px){
  img{
    width: 290px;
    height:290px;
  }
}
h1{
    padding-left:10px;
    color:#000;
    Font-size:30px;
}
`

const Buttons = styled.div`
padding-top:50px;
margin-bottom:40px;
text-align: center;
align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

a{
        
    color:#000;
     padding: 8px 25px 9px 25px;
     border: 2px  solid #000;
     border-radius: 5px;
     font-size: 23px;
     transition: 0.3s;
    
   }
   a:hover{
     
     border: 2px  solid #fff;
     background: #D0A257;
   }
`