import React from 'react'
import ContactHead from '../ContactsComponents/ContactHead'
import ArtisCard from './ArtisCard'
import ArtisCard2 from './ArtisCard2'
import CassyArtisCard from './cassyArtisCard'
import BobArtistCard from './bobArtistCard'
import SurajArtistBio from './SurajArtistBio'
import { Helmet } from 'react-helmet-async'

function ArtisGallery() {
    return (
        <>
            <Helmet>
                <title>Artist Gallery | TattooTrippers  </title>
                <meta name="description" content="Transform your body into a canvas of extraordinary artistry with the absolute best tattoo artist near you! Our skilled and passionate tattoo professionals are wizards of ink, dedicated to turning your wildest ideas into jaw-dropping masterpieces. From intricate details to bold statements, our artists redefine the art of self-expression. Unleash your creativity and make a statement with the best—where every tattoo tells a unique story, and your body becomes a gallery of unparalleled craftsmanship. Elevate your ink game, because ordinary is just not our style!" />

                <link rel="canonical" href="https://www.tattootrippers.com/artis-service" />
                <meta property="og:image" content="https://www.tattootrippers.com/static/media/tattootripermain.png.42f351b21d9937ece453.jpg" />
                <meta property="og:url" content="https://www.tattootrippers.com/artis-service" />
                <meta property="og:description" content="Transform your body into a canvas of extraordinary artistry with the absolute best tattoo artist near you! Our skilled and passionate tattoo professionals are wizards of ink, dedicated to turning your wildest ideas into jaw-dropping masterpieces. From intricate details to bold statements, our artists redefine the art of self-expression. Unleash your creativity and make a statement with the best—where every tattoo tells a unique story, and your body becomes a gallery of unparalleled craftsmanship. Elevate your ink game, because ordinary is just not our style!" />
                <meta property="og:title" content="Best Tattoo Studio in Goa / India / Arambol |  TattooTrippers | Best tattoo studio in arambol " />
                <meta property="og:type" content="website" />
                <meta property='og:image:width' content=' 1200'></meta>
                <meta property='og:image:height' content=' 722'></meta>
            </Helmet>
            <ContactHead title="Artist Gallery" />
            <ArtisCard />
            <CassyArtisCard />
            <BobArtistCard />
            <SurajArtistBio />
            {/* <ArtisCard2 /> */}

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
        </>
    )
}

export default ArtisGallery