import React, { useEffect } from 'react'
import tatootripersmain from '../../assets/images/tattootripermain.png.jpg'
import styled from 'styled-components'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

function TopHeadText() {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 600,
      easing: 'ease-in-sine',
      delay: 100,
    });
  }, [])

  return (
    <Container>
      <Section>
        <Left>

        </Left>
        <Right>
          <p className='topbigtext'>
            ENJOY YOUR <br /><br />TATTOO TRIP WITH
          </p>
          <img src={tatootripersmain}></img>
          <Text>
            <h1>START A FREE
            </h1>
            <br />
            <h2>CONSULTATION</h2>
            <br />
            <br />
            <Link to="/book-now" className="btn" >CHAT NOW </Link>

          </Text>
        </Right>
      </Section>

    </Container>
  )
}

export default TopHeadText
const Section = styled.div`
display:flex;
justify-content: space-between;
text-align: center;
align-items: center;
@media screen and (max-width: 768px){
display:flex;

}
`

const Left = styled.div`
width:30%;

@media screen and (max-width: 768px){
  width:0%;
}

`

const Right = styled.div`
width:70%;

p{
  text-align: center;
  margin-top:20px;
  font-size:60px;
  line-height: 0.6;
  letter-spacing: 0.5px;
  color:orange;
  font-weight: 900;
}
img
{
  width:700px;
height:140px;
align-items:center;
margin-top:20px;
}

@media screen and (max-width: 768px){
  width:100%;
  p{
    font-size:30px;
    word-spacing: 1px;
    margin-top:0px;
  }
  img
{
  width:320px;
height:80px;
align-items:center;
margin-top:20px;
}
}

`
const Container = styled.div`

weight:60%;
margin-top:30px;
margin-right:0px;
align-items:right;
text-align: right;





@media screen and (max-width: 768px){
    height:30vh;
    padding:30px;
}


`

const Text = styled.div`
    padding-top:20px;
    text-align: center;
    align-items: center;
    
     
  
      a{
        text-align: right;
       
        background: #D0A257;
        color:#fff;
         padding: 8px 25px 9px 25px;
         border: 2px  solid #fff;
         border-radius: 50px;
         font-size: 23px;
         transition: 0.3s;
        
       }
       a:hover{
         
         border: 2px  solid #fff;
         background: green;
       }
      h2{
        font-size:20px;
        padding:0px;
        word-spacing: 15px;
        letter-spacing: 2px;
       color:#fff;
       font-weight: bold;
     }
     h1{
        font-size:35px;
        padding:0px;
        font-weight: normal;
        word-spacing: 5px;
        letter-spacing: 0px;
       color:#fff;
    }

 p{
    margin-top:30px;
    font-size:22px;
    word-spacing: 2px;
    letter-spacing: 0.5px;
    color:#fff;
 }

 @media screen and (max-width: 768px){
    padding-top:20px;
    line-height: 1;
    margin-left: 10px;
    
     p{
        margin-top:20px;
        font-size:14px;
        word-spacing: 4px;
        letter-spacing: 0.5px;
        line-height: 1.2;
        color:#fff;
     }

     a{
        
        color:#fff;
         padding: 8px 25px 9px 25px;
         border: 2px  solid #fff;
         border-radius: 5px;
         font-size: 16px;
         transition: 0.3s;
        
       }
       a:hover{
         
         border: 2px  solid #fff;
         background: #D0A257;
       }
 }
`