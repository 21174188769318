import React, { useState } from "react";
import styled from 'styled-components'

function Form() {
    const [userData, setUserData] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",

        message: "",
    });

    let name, value;
    const postUserData = (event) => {
        name = event.target.name;
        value = event.target.value;

        setUserData({ ...userData, [name]: value });
    };

    // connect with firebase
    const submitData = async (event) => {
        event.preventDefault();
        const { firstName, lastName, phone, email, message } = userData;

        if (firstName && lastName && phone && email && message) {
            const res = fetch(
                "https://onlinetalks-8f74a-default-rtdb.firebaseio.com/userContact.json",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        firstName,
                        lastName,
                        phone,
                        email,

                        message,
                    }),
                }
            );

            if (res) {
                setUserData({
                    firstName: "",
                    lastName: "",
                    phone: "",
                    email: "",

                    message: "",
                });
                alert("Data Stored");
            } else {
                alert("plz fill the data");
            }
        } else {
            alert("plz fill the data");
        }
    };
    return (
        <Container>


            <form method="POST">
                <Top>

                    <input
                        type="text"
                        name="firstName"
                        id=""
                        placeholder="First Name"
                        value={userData.firstName}
                        onChange={postUserData}

                    />
                    <input
                        type="text"
                        id=""
                        name="lastName"
                        placeholder='Last Name'

                        value={userData.lastName}
                        onChange={postUserData}
                    />
                </Top>
                <input
                    type="email"
                    name="email"
                    id=""
                    placeholder='EMAIL ADDRESS *'
                    value={userData.email}
                    onChange={postUserData}
                />
                <input type="text"
                    id=""
                    name="phone"
                    placeholder='MOBILE NUMBER *'

                    value={userData.phone}
                    onChange={postUserData}
                />
                <textarea
                    typeof='text'
                    name="message"
                    id=""
                    rows="5" cols="50"
                    placeholder='DO YOU HAVE ANY IDEA IN MIND? *'

                    value={userData.message}
                    onChange={postUserData}
                />



                <input className='btn' type="submit" value="START A FREE CONSULTATION" onClick={submitData} />
            </form>
        </Container>
    )
}

export default Form

const Container = styled.div`
padding-top: 100px;
padding-bottom: 50px;
padding-left: 150px;
padding-right: 150px;

input{
    width:100%; 
    padding:10px;
    margin-top:40px;
    background:#FAFAFA;
    border: 1px solid #ccc;
}
textarea{
     resize: vertical; 
    width:100%; 
    padding:10px;
    margin-top:40px;
    border: 1px solid #ccc;
}
.btn{
    font-size:16px;
    color: #fff;
    background-color: #272727;
    border-color: #272727;
    cursor: pointer;
}
form{
padding:10px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

}

.btn:hover{
    background-color: #515050;
    
}
@media screen and (max-width: 768px){
    padding-top: 100px;
padding-bottom: 50px;
padding-left:0px;
padding-right: 0px;

textarea{
    
   padding:15px;
   margin-top:20px;

}

input{
   
    padding:15px;
    margin-top:20px;
   
}
}
`
const Top = styled.div`
display:flex;

input{
    border: 1px solid #ccc;
    background:#FAFAFA;
    width:50%; 
    padding:10px;
   margin:5px;
}
@media screen and (max-width: 768px){
    flex-wrap:wrap;
    input{
        
        width:400px; 
        padding:15px;
      
    }
}
`
