import React from 'react'
import styled from 'styled-components'
import img1 from '../../assets/artist/shubham.jpg'
import Twitter from '../../assets/images/twitter.svg'
import Instagram from '../../assets/images/Instagram.svg'
import Youtube from '../../assets/images/Youtube.svg'

function ArtisCard2() {
    return (
        <>
            <Conatiner>


                <RightSide>
                    <h1>Shubham Malviya</h1>
                    <SocialLink>

                        <a target='blank' href=" https://twitter.com/linkosm_in"><li> <img src={Twitter} alt='Linkosm Twitter Logo' title='Linkosm Twitter Logo' /></li></a>
                        <a target='blank' href="https://www.instagram.com/linkosm_in/"><li> <img src={Instagram} alt='Linkosm Instagram Logo' title='Linkosm Instagram Logo' /></li></a>
                        <a target='blank' href="https://www.youtube.com/@linkosm"> <li> <img src={Youtube} alt='Linkosm YouTube Logo' title='Linkosm YouTube Logo' /></li></a>


                    </SocialLink>
                    <p>"Always be a learner – Shubham believes that the process of learning never ends
                        and he strives to keep himself as a perpetual learner,  he says
                        "In the path of art, the more effort you put in, the more you'll shine."
                    </p>
                    <br />
                    <p>"Shubham is a founder of tattoo trippers and he has been in the tattoo field for 8 years now. After completing his graduation, he decided that he wanted to become a tattoo artist. Before pursuing this path, he worked in various places, such as a call center, an ice cream parlor, a photocopy shop, a data entry office, and some other places. However, he was never content with any of these jobs. He always felt that he was meant for some form of artistry. "During any job, Shubham never felt a sense of happiness; he saw himself as a robot. One day, he made the decision that he needed to pursue a job where there were no limits to what he could learn."</p>


                </RightSide>
                <LeftSide>
                    <RightImage>
                        <img src={img1} alt='' title='' />
                    </RightImage>
                </LeftSide>

            </Conatiner>
            <BottomContainer>
                <p>Shubham thought about their passions, and it suddenly struck them that during their school days, he  used to create tattoos for their friends using markers and pens, and it brought them immense joy. It was then that they made the decision to become a tattoo artist." he began to explore the world of tattoos and gathered information. They practiced tattooing in several places and finally, he settled in Goa to pursue their passion for tattoos. </p>
                <br />
                <p>"Along with tattoos, Shubham is also interested in Indian classical music and plays various musical instruments like harmonium, dholak, guitar, jambey, congo, keyboard and asalato .</p>
                <br />
                <p>shubham has delighted thousands of customers through his artistry and has embraced tattooing as his way of life."</p>

            </BottomContainer>


        </>
    )
}

export default ArtisCard2


const BottomContainer = styled.div`
padding:30px;
`
const SocialLink = styled.div`
padding-left:0px;
display:flex;
flex-wrap:no-wrap;

li{
    img{
        width:55px;
        height:55px;
       padding:15px;
       cursor: pointer;
      
    }
    &hover{
        color:#fff;
       }
}

@media screen and (max-width: 768px)_
li{
    a{
        margin-top:30px;
    }
}
`

const Conatiner = styled.div`
display:flex;

// text-align:start;
// align-items: top;
`

const RightSide = styled.div`
padding-top:80px;

h1{
    padding-left:10px
    text-align: right;
    align-items: right;
}
`
const LeftSide = styled.div`

`


const RightImage = styled.div`
width:100%;
padding:30px;
outline: none;
border-radius: 50%;
img{
  width: 300px;
  height:300px;
  border-radius: 50%;
}
@media screen and (max-width: 768px){
  img{
    width: 290px;
    height:290px;
  }
}
`