import React from 'react'
import { styled } from 'styled-components'


function HomeShow(props) {
    return (
        <Container>

            <Image>
                <img src={props.img1} alt='Gallery Images'></img>
            </Image>

            <Image>
                <img src={props.img2} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={props.img3} alt='Gallery Images'></img>
            </Image>

        </Container>
    )
}

export default HomeShow

const Container = styled.div`
display:flex;
flex-wrap:wrap;
justify-content:space-around;
`


const Image = styled.div`
object-fit: cover;

margin:5px;
img{
  
    width:250px;
height:400px;
}
&:hover{


    
    img{
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        width:250px;
        height:400px;
    }
}
`