import React from 'react'

import HeadSection from '../Components/HomeComponent/HeadSection'

import ShortAbout from '../Components/AboutSection/ShortAbout'
import MidText from '../Components/HomeComponent/MidText'

import HomeShow from '../Components/Pricing/HomeShow'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'
import reactangle from '../assets/images/reactangle1.png'
import reactangle2 from '../assets/images/reactangle2.png'
import reactangle3 from '../assets/images/reactangle3.png'
import { Helmet } from 'react-helmet-async'

function HomeScreen() {
  window.scrollTo(0, 0);
  return (
    <>

      <Helmet>
        <title>Best Tattoo Studio in Goa / India / Arambol |  TattooTrippers | Best tattoo studio in arambol </title>
        <meta name="description" content="We are the best Tattoo Studio In Goa, India with affordable /  Our skilled and experienced tattoo artists, Best Tattoo Artist in Goa / Arambol India " />

        <meta property="og:image" content="https://www.tattootrippers.com/static/media/tattootripermain.png.42f351b21d9937ece453.jpg" />
        <meta property="og:url" content="https://www.tattootrippers.com/" />
        <meta property="og:description" content="We are the best Tattoo Studio In Goa, India with affordable /  Our skilled and experienced tattoo artists, Best Tattoo Artist in Goa / Arambol India" />
        <meta property="og:title" content="Best Tattoo Studio in Goa / India / Arambol |  TattooTrippers | Best tattoo studio in arambol " />
        <meta property="og:type" content="website" />
        <meta property='og:image:width' content=' 1200'></meta>
        <meta property='og:image:height' content=' 722'></meta>
      </Helmet>

      <HeadSection />

      <MidText title="TattooTrippers" dec="Tattooing || Tattoo Design" />
      <ShortAbout />
      <MidText title="Our Latest Work" />
      <HomeShow
        img1={reactangle}
        img2={reactangle2}
        img3={reactangle3}
      />
      <Buttons>   <Link to="gallery">VIEW GALLERY</Link></Buttons>

    </>
  )
}

export default HomeScreen

const Buttons = styled.div`
padding-top:50px;
margin-bottom:40px;
text-align: center;
align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

a{
        
    color:#000;
     padding: 8px 25px 9px 25px;
     border: 2px  solid #000;
     border-radius: 5px;
     font-size: 23px;
     transition: 0.3s;
    
   }
   a:hover{
     
     border: 2px  solid #fff;
     background: #D0A257;
   }
`

