import React from 'react'
import styled from 'styled-components'
import img1 from '../../assets/artist/suraj ppppp.jpg'
import Twitter from '../../assets/images/twitter.svg'
import Instagram from '../../assets/images/Instagram.svg'
import Youtube from '../../assets/images/Youtube.svg'

import HomeShow from '../Pricing/HomeShow'
import { Link } from 'react-router-dom'
import reactangle from '../../assets/images/LION.jpg'
import reactangle2 from '../../assets/images/WOLF .jpg'
import reactangle3 from '../../assets/images/Agf.jpg'

function SurajArtistBio() {
    return (
        <>
            <Conatiner>
                <LeftSide>
                    <RightImage>
                        <img src={img1} alt='' title='' />
                        <h1>Suraj</h1>
                        <SocialLink>

                            <a target='blank' href=" https://twitter.com/linkosm_in"><li> <img src={Twitter} alt='Linkosm Twitter Logo' title='Linkosm Twitter Logo' /></li></a>
                            <a target='blank' href="https://www.instagram.com/linkosm_in/"><li> <img src={Instagram} alt='Linkosm Instagram Logo' title='Linkosm Instagram Logo' /></li></a>
                            <a target='blank' href="https://www.youtube.com/@linkosm"> <li> <img src={Youtube} alt='Linkosm YouTube Logo' title='Linkosm YouTube Logo' /></li></a>


                        </SocialLink>
                    </RightImage>
                </LeftSide>

                <RightSide>

                    <p>"I have had a passion for sketching and painting since childhood, but I never thought I would become a tattoo artist. For a while, I stopped sketching and painting, trying out many other things, but I couldn't find peace in any of them. Then, I met a friend who was already a tattoo artist, and he saw my sketches. <br /> <br />He suggested that I try tattooing, so I started sketching and painting again. <br /> <br />I practiced a lot, and it took me about a year to properly learn tattooing. I attended many tattoo conventions and workshops, learning many new things. <br /><br /> Even now, I continue to learn and improve my skills. It has been six years since I entered the tattoo industry. I have worked in 3-4 different studios, gained experience, freelanced, and participated in events, graffiti, and more."
                    </p>



                </RightSide>

            </Conatiner>
            <BottomContainer>

            </BottomContainer>
            <HomeShow
                img1={reactangle}
                img2={reactangle2}
                img3={reactangle3}
            />
            <Buttons>   <Link to="/gallery">VIEW GALLERY</Link></Buttons>

        </>
    )
}

export default SurajArtistBio

const BottomContainer = styled.div`
padding:30px;
p{
    color:#000;
    font-size:18px;
}
`
const SocialLink = styled.div`
padding-left:0px;
display:flex;
flex-wrap:no-wrap;

li{
    img{
        width:55px;
        height:55px;
       padding:15px;
       cursor: pointer;
      
    }
    &hover{
        color:#fff;
       }
}

@media screen and (max-width: 768px)_
li{
    a{
        margin-top:30px;
    }
}
`

const Conatiner = styled.div`
display:flex;

@media screen and (max-width: 768px){
    flex-wrap:wrap;
}
p{
    color:#000;
    font-size:18px;
}
`

const RightSide = styled.div`
padding-top:80px;

h1{
    padding-left:10px;
    color:#000;
    Font-size:40px;
}
@media screen and (max-width: 768px){
    padding-top:10px;
    padding:10px;
}
`
const LeftSide = styled.div`


`


const RightImage = styled.div`
width:100%;
padding:30px;
outline: none;
border-radius: 50%;
margin-top:50px;
img{
  width: 300px;
  height:300px;
  border-radius: 50%;
}
@media screen and (max-width: 768px){
  img{
    width: 290px;
    height:290px;
  }
}
h1{
    padding-left:10px;
    color:#000;
    Font-size:30px;
}
`

const Buttons = styled.div`
padding-top:50px;
margin-bottom:40px;
text-align: center;
align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

a{
        
    color:#000;
     padding: 8px 25px 9px 25px;
     border: 2px  solid #000;
     border-radius: 5px;
     font-size: 23px;
     transition: 0.3s;
    
   }
   a:hover{
     
     border: 2px  solid #fff;
     background: #D0A257;
   }
`