import React from 'react'
import TopHead from '../HomeComponent/TopHead'
import tattootripertext from '../../assets/images/tattootripers3.png'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import BurgerIcon from '../HomeComponent/BurgerIcon'

function CommonHeader() {
    return (
        <Container>
            <h1 style={{ color: 'black', fontSize: '35px', textAlign: 'center', alignItems: 'center', marginTop: '20px', }}>TattooTripers</h1>
            <TopHead />
            <BurgerIcon />
        </Container>
    )
}

export default CommonHeader

const Container = styled.div`
height:70px;
weight:100%;
align-item: center;
display: flex;
justify-content:space-around;
top: 30;
left: 0;
right: 0;
z-index: 1;
opacity: 1;
p{
    color:#000;
}
h1{
    font-family: 'Dancing Script', cursive;
}

@media screen and (max-width: 768px){
    justify-content:space-between;
}


`




const Logos = styled.div`
display:flex;
flex-wrap:no-wrap;
align-items: center;
    .mainlogo{
        width:200px;
        height:40px;
        cursor: pointer;

    }

    
    @media screen and (max-width: 768px){
       
    }
`


