import React from 'react'
import styled from 'styled-components'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
function WhatsAppButtom() {
    return (
        <Wrapper>
            <Buttom>

                <li><a href="https://wa.me/918982703510"
                    target="_blank"
                    rel="noreferrer"><WhatsAppIcon className='icons' /></a></li>
            </Buttom>
        </Wrapper>
    )
}

export default WhatsAppButtom

const Wrapper = styled.section`
display: flex;
justify-content: center;
align-items: center;
position: relative;

`
const Buttom = styled.div`
background:#25D366;
z-index:1000;
bottom:1rem;

margin:10px;
width:50px;
height:50px;
border-radius:100%;
position: fixed;

right: 2rem;
z-index: 999;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;

li{
    
    align-items: center;
    text-align: center;
    .icons{
        padding-top:7px;
        color:#fff;
        width:40px;
        height:40px;
    }
}
@media screen and (max-width: 768px){
    left:5px;
    bottom:5px;
}
`