import React from "react";
import './App.css';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomeScreen from "./Screens/HomeScreen";
import BottomFooter from "./Components/Footer/BottomFooter";
import AboutScreen from "./Screens/ABOUT";
import GalleryScreen from "./Screens/GalleryScreen";

import ContactScreen from "./Screens/Contacts";
import CopyRight from "./Components/Footer/CopyRight";
import QuestionScreen from "./Screens/Questions";
import WhatsAppButtom from "./Constant/WhatsAppButtom";
import ArtisGallery from "./Components/ArtisGallery";
import PiercingScreen from "./Screens/PIERCING";
function App() {
  return (
    <Router>

      <ToastContainer position="bottom-center" hideProgressBar={false} />
      <WhatsAppButtom />
      <Routes>

        <Route exact path="/" element={<HomeScreen />} />
        <Route exact path="/about" element={<AboutScreen />} />
        <Route exact path="/gallery" element={<GalleryScreen />} />
        <Route exact path="/service" element={<QuestionScreen />} />
        <Route exact path="/Piercing" element={<PiercingScreen />} />
        <Route exact path="/artis-service" element={<ArtisGallery />} />
        <Route exact path="/book-now" element={<ContactScreen />} />

      </Routes>
      {/* <TextFooter /> */}
      <CopyRight />
      <BottomFooter />

    </Router>
  );
}

export default App;
