import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Twitter from '../../assets/images/twitter.svg'
import Instagram from '../../assets/images/Instagram.svg'
import Youtube from '../../assets/images/Youtube.svg'
function BottomFooter() {
    return (
        <Container>
            <Pages>
                <li>Designed & Developed by <a href='https://anextin.com/' target='blank'>ANextIn Team</a></li>


            </Pages>
            <SocialLink>

                <a target='blank' href=" https://twitter.com/linkosm_in"><li> <img src={Twitter} alt='Linkosm Twitter Logo' title='Linkosm Twitter Logo' /></li></a>
                <a target='blank' href="https://www.instagram.com/tattoo_trippers/"><li> <img src={Instagram} alt='Linkosm Instagram Logo' title='Linkosm Instagram Logo' /></li></a>
                <a target='blank' href="https://www.youtube.com/@linkosm"> <li> <img src={Youtube} alt='Linkosm YouTube Logo' title='Linkosm YouTube Logo' /></li></a>


            </SocialLink>
            <CopyRight>
                <li><Link to="https://www.tattootrippers.com/">TattooTrippers</Link> © 2023 All rights reserved</li>
            </CopyRight>
        </Container>
    )
}

export default BottomFooter
const Container = styled.div`
padding:50px;
display:flex;
flex-wrap:wrap;

justify-content:space-between;
background:#1A2438;
border-top:1px solid #AAA;
@media screen and (max-width: 768px){
    padding:0px;
    align-items:center;
    text-align:center;
}
`

const SocialLink = styled.div`
padding-left:10px;
display:flex;
flex-wrap:no-wrap;

li{
    img{
        width:55px;
        height:55px;
       padding:15px;
       cursor: pointer;
      
    }
    &hover{
        color:#fff;
       }
}

@media screen and (max-width: 768px)_
li{
    a{
        margin-top:30px;
    }
}
`
const CopyRight = styled.div`
li{
    color:#fff;
    font-size:15px;
    a{
        color:#fff;
    }
}
@media screen and (max-width: 768px){
    margin-top:40px;
    margin-bottom:40px;
    margin-left:auto;
    margin-right:auto;
    align-items:center;
    width:60%;
}

`
const Pages = styled.div`
display:flex;
flex-wrap:wrap;
li{
    color:#fff;
    font-size:15px;
    padding-right:20px;
   
}
a{
    color:#2b47e5;
}
@media screen and (max-width: 768px){
    margin-top:40px;
    margin-left:auto;
    margin-right:auto;
    align-items:center;
    width:90%;
}
`