import React from 'react'


function CopyRight() {
    return (


        <>
            <iframe style={{ width: '100%', height: '500px' }} title='TattooTrippers Location | Beat tattoo studio in goa 
' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3841.38879410928!2d73.71127729999999!3d15.6775028!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbfef4898badc71%3A0x87a0c17ea7ef243f!2stattoo%20trippers!5e0!3m2!1sen!2sin!4v1701075321563!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>



            {/* <Container> */}

            {/* <TopSection>



                    {/* <Text>
                        <li>meet@anextin.com</li>
                        <li>Phone :+91 8982703510</li>
                    </Text> */}
            {/* </TopSection> */}

            {/* </Container> */}
        </>
    )
}

export default CopyRight

// const Container = styled.div`

// background:#1A2438;
// padding:90px;
// p{
//     color:#fff;
//     margin-top:30px;
// }

// @media screen and (max-width: 768px){
//     padding:20px; 
// }
// `
// const SocialLink = styled.div`
// padding-left:10px;
// display:flex;
// flex-wrap:no-wrap;

// li{
//     img{
//         width:55px;
//         height:55px;
//        padding:15px;
//        cursor: pointer;
      
//     }
//     &hover{
//         color:#fff;
//        }
// }

// @media screen and (max-width: 768px)_
// li{
//     a{
//         margin-top:30px;
//     }
// }
// `

// const TopSection = styled.div`
// overflow: hidden;
// margin-top:80px;
// display:flex;
// flex-wrap:wrap;
// justify-content: space-around;
// align-items: center;
// color:#fff;
// img{

//     width:160px;
//     height:120px;
// }
// .instagram{
//     color:#000;
//     width:70px;
//     height:70px;
//     padding:10px;
//     background:#fff;
//     border-radius: 100%;
// }



// @media screen and (max-width: 768px){
//     margin-top:40px;
//     img{

//         width:100px;
//         height:100px;
//     }

//     .instagram{
        
//         width:50px;
//         height:50px;
        
        
//     }
// }
// `

// const Text = styled.div`
// li{
//     font-size:25px;
//     padding:10px;
// }
// `