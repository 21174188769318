import React, { useEffect } from 'react'
import { styled } from 'styled-components'
import AOS from 'aos';
import 'aos/dist/aos.css';
function MidText(props) {

    useEffect(() => {
        AOS.init({
            offset: 200,
            duration: 400,
            easing: 'ease-in-sine',
            delay: 100,
        });
    }, [])

    return (
        <Text>
            <h1 data-aos="zoom-in">{props.title}</h1>

            <p data-aos="slide-up">
                {props.des}
            </p>

        </Text>
    )
}

export default MidText

const Text = styled.div`
    padding-top:50px;
    margin-bottom:40px;
    text-align: center;
    align-items: center;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
  
h1{
    color:#000;
    font-family: 'Dancing Script', cursive;
    font-size:50px;
    padding:30px;
    word-spacing: 15px;
    letter-spacing: 3px;
   
}
 h2{
    font-size:20px;
    word-spacing: 5px;
    letter-spacing: 0.5px;
color:#000;
 }
 p{
    margin-top:30px;
    font-size:17px;
    word-spacing: 2px;
    letter-spacing: 0.5px;
color:#000;
 }

 @media screen and (max-width: 768px){
    padding-top:100px;
    line-height: 1;

    h1{
        font-size:35px;
        padding:20px;
        word-spacing: 8px;
        letter-spacing: 2px;
        text-align: center;
   
    }
    h2{
        color:#444;
        font-size:13px;
        word-spacing: 1px;
        line-height: 1.4;
    
     }
     p{
        margin-top:20px;
        font-size:14px;
        word-spacing: 2px;
        letter-spacing: 0.5px;
    color:#000;
     }
 }
`