
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import FacebookIcon from '@mui/icons-material/Facebook';

import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

function TopHead() {


  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);





  return (
    <Container  >

      <Link to="/"><li>HOME</li></Link>
      <Link to="/about"><li>ABOUT</li></Link>


      <Link onClick={() => setOpen(!open)} ><li  >TATTOO</li></Link>

      {open ? <p style={{ marginTop: '50px', position: 'absolute', fontSize: '18px', color: '#fff', display: 'flex', alignItems: 'center', textAlign: 'center' }} onMouseLeave={() => setOpen(false)} onMouseOut={() => setShow(false)} ><Link to="/gallery" className='dropdown'>TATTOO GALLERY </Link>| <Link to='/artis-service' className='dropdown' > ARTIST GALLERY</Link></p> : null}


      <Link to='/Piercing' ><li >PIERCING</li></Link>

      {/* <Link onClick={() => setShow(!show)} ><li  >PIERCING</li></Link>

      {show ? <p style={{ marginTop: '50px', marginLeft: '170px', position: 'absolute', fontSize: '18px', color: '#fff', }} onMouseLeave={() => setShow(false)} onMouseOut={() => setShow(false)}> PIERCING  | BODY MODIFICATION  </p> : null}

 */}


      <Link to="/service"><li>FAQ</li></Link>
      <a target='blank' href=" https://twitter.com/linkosm_in"><li> <FacebookIcon /> </li></a>
      <a target='blank' href="https://www.instagram.com/tattoo_trippers/"><li> <InstagramIcon /></li></a>
      <a target='blank' href="https://www.youtube.com/@linkosm"> <li> <YouTubeIcon /></li></a>
      {/* 
     
      {/* <Link to="/book-now"><li className='bookbtn'>BOOK NOW</li></Link> */}
    </Container  >
  )
}

export default TopHead

const Container = styled.div`
height: 60px;
margin-top:10px;

display:flex;
flex-wrap:no-wrap;
justify-content: space-around;
align-item: right;
text-align: right;


.dropdown{
  font-size:14px;
}
.hovername{
  display:none;
}
  .bookbtn{
    color:#fff;
    background:#D0A257;
   padding-left:10px;
   padding-top:5px;
   padding-right:10px;

    &:hover{
      color:#fff;
      background:green;
    }
  }


a {
  font-weight: 700;
  font-size: 20px;
  
  text-align: center;
  color: #fff;
  padding: 15px 20px;
  flex-wrap: nowrap;
  display:flex;
}



@media screen and (max-width: 820px){
 a{
  font-size: 14px;
  padding: 10px 20px;
 }
}

a:hover{
  color: #D0A257;
}
a:active{
  color: #D0A257;
}

@media screen and (max-width: 768px){
  display:none;
}
`

