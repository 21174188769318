import React from 'react'
import reactangle from '../../assets/piercing/,KHGDFBB.jpg'
import reactangle2 from '../../assets/piercing/BODY .jpg'
import reactangle10 from '../../assets/piercing/EAR PIERCING .jpg'
import reactangle3 from '../../assets/piercing/KHB.jpg'
import reactangle4 from '../../assets/piercing/NOSE PIERCING .jpg'
import reactangle5 from '../../assets/piercing/NOSE PIERCING FINAL .jpg'
import reactangle6 from '../../assets/piercing/OIP.jpg'
import reactangle7 from '../../assets/piercing/PIERCING.jpg'
import reactangle8 from '../../assets/piercing/PIERCING.jpg ADH.jpg'
import reactangle9 from '../../assets/piercing/SEPTUM .jpg'

import styled from 'styled-components'
function ImageOverlay() {
    return (
        <Container>
            <Image>
                <img src={reactangle} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle2} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle3} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle4} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle5} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle6} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle7} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle8} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle9} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle10} alt='Gallery Images'></img>
            </Image>


        </Container>
    )
}

export default ImageOverlay
const Container = styled.div`
display:flex;
flex-wrap:wrap;
justify-content:space-around;
`


const Image = styled.div`
object-fit: cover;
border-radius: 25px;
margin:5px;
img{
    border-radius: 15px;
    width:250px;
height:400px;
}
&:hover{


    
    img{
  
        width:250px;
    height:400px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
`