import React, { useState } from 'react'
import styled from "styled-components";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import BurgerMenu from "./BurgerMenu";


function BurgerIcon() {
  const [burgerStatus, setBurgerStatus] = useState(false);
  return (
    <Container1 >


      <RightMenu>
        <CostomMenu onClick={() => setBurgerStatus(true)} />


      </RightMenu>

      <BurgerNav show={burgerStatus}>
        <CloseWraper>
          <CustomeClose onClick={() => setBurgerStatus(false)} />


        </CloseWraper>

        <BurgerMenu after={setBurgerStatus} />
      </BurgerNav>
    </Container1 >
  )
}

export default BurgerIcon

const Container1 = styled.div`
 display:none;
  align-item: center;
  
  @media screen and (max-width: 768px) {
    display:flex;
    margin-right:10px;

   margin-top:5px;
    
  }
`

const RightMenu = styled.div``;

const CostomMenu = styled(MenuIcon)`
  color: #fff;

  cursor: pointer;
`;



const BurgerNav = styled.div`
  position: fixed;
  top: 0;
 
  ${"" /* bottom:0; */}
  right: 0;
  background: #fff;
  width: 250px;
  display: none;
  z-index: 16;
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: start;
  transform: ${(props) => (props.show ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.2s;
  li {
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    a {
      
      font-wight: 600px;
    }
  }
`;

const CustomeClose = styled(CloseIcon)`
  cursor: pointer;
`;
const CloseWraper = styled.div`
  display: flex;
  justify-content: flex-end;
`;
