import React from 'react'
import herobg2 from '../../assets/images/mainhead.png'
import tattootripertext from '../../assets/images/tattootripers2.png'
import styled from 'styled-components'
import Header from '../../Components/HomeComponent/Header'
function QuestionHead() {
  return (
    <Container>
      <Header img={tattootripertext} />
      <h1>

        ALL YOUR QUESTIONS, ANSWERED.</h1>
    </Container>
  )
}

export default QuestionHead

const Container = styled.div`
padding-top:30px;
height:90vh;
weight:100%;
background-image: url(${herobg2}); 
background-repeat: no-repeat;
background-size: cover;
background-attachment: fixed;
background-position: center center;   
h1{
    padding-top:300px;
    color:#fff;
      height:40px; 
    z-index: 9;
    text-align: center;
    align-items: center;
      margin-left: auto;
      margin-right: auto;
      width: 63%;
    }

 
 
@media screen and (max-width: 768px){
    padding-top:20px;

    h1{
      padding-top:250px;
      font-size:20px;
      width: 83%;
    }
}
`