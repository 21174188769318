import React, { useState } from 'react'
import styled from 'styled-components'
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import CardHeader from "@mui/material/CardHeader";
import Container2 from "@mui/material/Container";
import CardContent from "@mui/material/CardContent";

import KeyboardArrowDownIcon from
    '@mui/icons-material/ControlPoint';
import KeyboardArrowUpIcon from
    '@mui/icons-material/ControlPoint';
import IconButton from "@mui/material/IconButton";

function QuestionAns() {
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [open5, setOpen5] = useState(false);
    const [open6, setOpen6] = useState(false);
    const [open7, setOpen7] = useState(false);
    return (
        <Container>
            <Card sx={{
                width: '90%',
                backgroundColor: "#fff",
                marginBottom: '10px',
                marginTop: '40px;'

            }}>
                <CardHeader
                    title="How can I book in?"
                    action={
                        <IconButton
                            onClick={() => setOpen1(!open1)}
                            aria-label="expand"
                            size="small"

                        >
                            {open1 ? <KeyboardArrowUpIcon />
                                : <KeyboardArrowDownIcon />}
                        </IconButton>
                    }
                ></CardHeader>
                <div style={{
                    backgroundColor: "#AAA",

                }}>
                    <Collapse in={open1} timeout="auto"
                        unmountOnExit>
                        <CardContent>
                            <Container2 sx={{
                                height: '100%',
                                lineHeight: 2,
                                fontSize: '16px',
                                boxShadow: 0,
                                width: '90%',


                            }} >
                                Please email in and follow up with a phone call, however, if you are in the area, by all means, <br /> knock on the door and we will try and help as best we can. We always have someone on hand to help guide you through the process at the shop. If you are coming in person, it is always worth ringing first and if we think you need it we’ll book a consultation with one of our artists.  <br /> We do need to see images of the kind of thing you are after so it's always helpful if you can email them in with an idea of size in cm or inches and where the tattoo is going on your body. Remember that if you email we may take longer to respond, sometimes up to a week, and unfortunately computers and servers do sometimes let us down so always follow up with a phone call. We cannot check availability or book you in over email.
                            </Container2>
                        </CardContent>
                    </Collapse>
                </div>
            </Card>
            <Card sx={{
                width: '90%',
                backgroundColor: "#fff",
                marginBottom: '10px',

            }}>
                <CardHeader
                    title="How can I book in? What should I do before my appointment?"
                    action={
                        <IconButton
                            onClick={() => setOpen2(!open2)}
                            aria-label="expand"
                            size="small"

                        >
                            {open2 ? <KeyboardArrowUpIcon />
                                : <KeyboardArrowDownIcon />}
                        </IconButton>
                    }
                ></CardHeader>
                <div style={{
                    backgroundColor: "#AAA",

                }}>
                    <Collapse in={open2} timeout="auto"
                        unmountOnExit>
                        <CardContent>
                            <Container2 sx={{
                                height: '100%',
                                lineHeight: 2,
                                boxShadow: 0,
                                width: '90%',
                                fontSize: '16px',

                            }} >
                                Once you’ve booked your appointment your artist will take care of your design work and preparation. Your job is to turn up clean, sober, and well-rested in plenty of time for your allotted slot. If you have an afternoon appointment, you can always ring ahead to check if your artist is running behind or if they would like you in earlier. Tattooing is not a science it is an art. We cannot therefore always be held to time constraints, although we do our best not to keep you waiting around unduly.
                            </Container2>
                        </CardContent>
                    </Collapse>
                </div>
            </Card>
            <Card sx={{
                width: '90%',
                backgroundColor: "#fff",
                marginBottom: '10px',

            }}>
                <CardHeader
                    title="Does it hurt?"
                    action={
                        <IconButton
                            onClick={() => setOpen3(!open3)}
                            aria-label="expand"
                            size="small"

                        >
                            {open3 ? <KeyboardArrowUpIcon />
                                : <KeyboardArrowDownIcon />}
                        </IconButton>
                    }
                ></CardHeader>
                <div style={{
                    backgroundColor: "#AAA",

                }}>
                    <Collapse in={open3} timeout="auto"
                        unmountOnExit>
                        <CardContent>
                            <Container2 sx={{
                                height: '100%',
                                lineHeight: 2,
                                boxShadow: 0,
                                width: '90%',
                                fontSize: '16px',

                            }} >
                                Pain is an illusion of the mind
                                tattoo never gives you pain it gives you the sensation of happiness Pain is very subjective and each person experiences their tattoo differently. In general, we can assure you, that it is more of a discomfort than scream-out-loud pain, and if you pay attention to your artist's instructions you are in a relaxed frame of mind.
                            </Container2>
                        </CardContent>
                    </Collapse>
                </div>
            </Card>
            <Card sx={{
                width: '90%',
                backgroundColor: "#fff",

                marginBottom: '10px',
            }}>
                <CardHeader
                    title="Do you do walk-ins?"
                    action={
                        <IconButton
                            onClick={() => setOpen4(!open4)}
                            aria-label="expand"
                            size="small"

                        >
                            {open4 ? <KeyboardArrowUpIcon />
                                : <KeyboardArrowDownIcon />}
                        </IconButton>
                    }
                ></CardHeader>
                <div style={{
                    backgroundColor: "#AAA",

                }}>
                    <Collapse in={open4} timeout="auto"
                        unmountOnExit>
                        <CardContent>
                            <Container2 sx={{
                                height: '100%',
                                lineHeight: 2,
                                boxShadow: 0,
                                width: '90%',
                                fontSize: '16px',

                            }} >
                                We are essentially an appointment-based studio but we are happy to do walk-ins and if an artist is free and you happen to drop in we will endeavor to accommodate you, However, to get the best results and ultimate experience we would always recommend you make an appointment.
                            </Container2>
                        </CardContent>
                    </Collapse>
                </div>
            </Card>
            <Card sx={{
                width: '90%',
                backgroundColor: "#fff",
                marginBottom: '10px',

            }}>
                <CardHeader
                    title="How much is a tattoo?"
                    action={
                        <IconButton
                            onClick={() => setOpen5(!open5)}
                            aria-label="expand"
                            size="small"

                        >
                            {open5 ? <KeyboardArrowUpIcon />
                                : <KeyboardArrowDownIcon />}
                        </IconButton>
                    }
                ></CardHeader>
                <div style={{
                    backgroundColor: "#AAA",

                }}>
                    <Collapse in={open5} timeout="auto"
                        unmountOnExit>
                        <CardContent>
                            <Container2 sx={{
                                height: '100%',
                                lineHeight: 2,
                                boxShadow: 0,
                                width: '90%',
                                fontSize: '16px',

                            }} >
                                Each tattoo is individual and tailored to each client so it is a bit like asking how long is a piece of string. However, when you book in we always try to price your piece and work to the budget agreed. We pride ourselves on providing a first-class service that remains within reach of most people's budgets.
                            </Container2>
                        </CardContent>
                    </Collapse>
                </div>
            </Card>
            <Card sx={{
                width: '90%',
                backgroundColor: "#fff",
                marginBottom: '10px',

            }}>
                <CardHeader
                    title="Are you ok if we hire additional videographers?"
                    action={
                        <IconButton
                            onClick={() => setOpen6(!open6)}
                            aria-label="expand"
                            size="small"

                        >
                            {open6 ? <KeyboardArrowUpIcon />
                                : <KeyboardArrowDownIcon />}
                        </IconButton>
                    }
                ></CardHeader>
                <div style={{
                    backgroundColor: "#AAA",

                }}>
                    <Collapse in={open6} timeout="auto"
                        unmountOnExit>
                        <CardContent>
                            <Container2 sx={{
                                height: '100%',
                                lineHeight: 2,
                                boxShadow: 0,
                                width: '90%',
                                fontSize: '16px',

                            }} >
                                No we are not. We try to keep our compositions clean by avoiding distractions and people carrying cameras are a distraction (including our own team members) . We work very closely within our team to ensure we do not obstruct each other's frames and for this very reason we work in exclusivity.
                            </Container2>
                        </CardContent>
                    </Collapse>
                </div>
            </Card>
            <Card sx={{
                width: '90%',
                backgroundColor: "#fff",
                marginBottom: '10px',

            }}>
                <CardHeader
                    title="Do I have to design the tattoo or do you do that?"
                    action={
                        <IconButton
                            onClick={() => setOpen7(!open7)}
                            aria-label="expand"
                            size="small"

                        >
                            {open7 ? <KeyboardArrowUpIcon />
                                : <KeyboardArrowDownIcon />}
                        </IconButton>
                    }
                ></CardHeader>
                <div style={{
                    backgroundColor: "#AAA",

                }}>
                    <Collapse in={open7} timeout="auto"
                        unmountOnExit>
                        <CardContent>
                            <Container2 sx={{
                                height: '100%',
                                lineHeight: 2,
                                boxShadow: 0,
                                width: '90%',
                                fontSize: '16px',

                            }} >
                                We like you to have a good idea of what you want and it helps to put together a scrapbook or mood board of images to help show us the direction you want to take. Our artists will draw and design on your behalf and the cost of this is absorbed into your tattoo, however, do not treat them as an etcha sketch; they are very busy and need time to rest too. The artists will only start working on your design once you have placed a deposit to secure an appointment.
                            </Container2>
                        </CardContent>
                    </Collapse>
                </div>
            </Card>

        </Container >
    )
}

export default QuestionAns

const Container = styled.div`
display:flex;
flex-wrap:wrap;
justify-content: space-around;
// background:#AAA;
`
