import React from 'react'
import QuestionHead from '../QuestionsComponents/QuestionHead'
import MidImages from '../QuestionsComponents/MidImages'
import QuestionAns from '../QuestionsComponents/QuestionAns'
import ContactHead from '../../Components/ContactsComponents/ContactHead'
import { Helmet } from 'react-helmet-async'
function QuestionScreen() {
    window.scrollTo(0, 0);
    return (
        <>
            <Helmet>
                <title>YOUR Tattoo  QUESTIONS | TattooTrippers  </title>
                <meta name="description" content="Discover the ultimate expression of artistry at the best tattoo studio near you. Our skilled and experienced tattoo artists are dedicated to transforming your ideas into stunning, personalized masterpieces. Explore a diverse range of styles and techniques in a welcoming environment, ensuring a memorable and unique tattoo experience. Visit us for expert craftsmanship and unparalleled creativity in the world of body art" />

                <link rel="canonical" href="https://www.tattootrippers.com/service" />
                <meta property="og:image" content="https://www.tattootrippers.com/static/media/tattootripermain.png.42f351b21d9937ece453.jpg" />
                <meta property="og:url" content="https://www.tattootrippers.com/service" />
                <meta property="og:description" content="Discover the ultimate expression of artistry at the best tattoo studio near you. Our skilled and experienced tattoo artists are dedicated to transforming your ideas into stunning, personalized masterpieces. Explore a diverse range of styles and techniques in a welcoming environment, ensuring a memorable and unique tattoo experience. Visit us for expert craftsmanship and unparalleled creativity in the world of body art" />
                <meta property="og:title" content="Best Tattoo Studio in Goa / India / Arambol |  TattooTrippers | Best tattoo studio in arambol " />
                <meta property="og:type" content="website" />
                <meta property='og:image:width' content=' 1200'></meta>
                <meta property='og:image:height' content=' 722'></meta>
            </Helmet>
            <ContactHead title=" ALL YOUR QUESTIONS, ANSWERED." />

            {/* <MidImages /> */}
            <QuestionAns />


        </>
    )
}

export default QuestionScreen