import React from 'react'
import ShortAbout from '../Components/AboutSection/ShortAbout'
import MidText from '../Components/HomeComponent/MidText'
import AboutUs from '../Components/AboutSection/AboutUs'
import CommonHeader from '../Components/Constant/CommonHeader.js'
import ContactHead from '../Components/ContactsComponents/ContactHead'
import { Helmet } from 'react-helmet-async'


function AboutScreen() {
    window.scrollTo(0, 0);
    return (
        <>
            <Helmet>
                <title>About Us | TattooTrippers  </title>
                <meta name="description" content="Experience unparalleled artistry at the best tattoo studio in Arambol. Our studio is a haven for creativity, where skilled tattoo artists bring your visions to life. Nestled in the vibrant atmosphere of Arambol, our studio offers a unique blend of expertise, passion, and a commitment to delivering exceptional tattoos. Whether you seek intricate designs, cultural motifs, or contemporary styles, our artists specialize in creating personalized, unforgettable body art. Visit us in Arambol for a tattoo journey that goes beyond the ordinary" />
                <meta property='' />
                <link rel="canonical" href="https://www.tattootrippers.com/about" />
                <meta property="og:image" content="https://www.tattootrippers.com/static/media/tattootripermain.png.42f351b21d9937ece453.jpg" />
                <meta property="og:url" content="https://www.tattootrippers.com/about" />
                <meta property="og:description" content="Experience unparalleled artistry at the best tattoo studio in Arambol. Our studio is a haven for creativity, where skilled tattoo artists bring your visions to life. Nestled in the vibrant atmosphere of Arambol, our studio offers a unique blend of expertise, passion, and a commitment to delivering exceptional tattoos. Whether you seek intricate designs, cultural motifs, or contemporary styles, our artists specialize in creating personalized, unforgettable body art. Visit us in Arambol for a tattoo journey that goes beyond the ordinary" />
                <meta property="og:title" content="Best Tattoo Studio in Goa / India / Arambol |  TattooTrippers | Best tattoo studio in arambol " />
                <meta property="og:type" content="website" />
                <meta property='og:image:width' content=' 1200'></meta>
                <meta property='og:image:height' content=' 722'></meta>
            </Helmet>

            <ContactHead title="About Us" />
            <br />
            <br />
            <ShortAbout />
            <AboutUs />
        </>
    )
}

export default AboutScreen