
import React from 'react'
import { styled } from 'styled-components'

// import img3 from '../../assets/images/circul3.png'
// import img1 from '../../assets/images/circul2.png'
// import { Link } from 'react-router-dom'

function AboutUs() {
  return (


    <Wrap>

      <ItemText>
        <h1 data-aos="fade-down">
          VISION
        </h1>
        <p data-aos="fade-left">
          Our talented tattoo artists are true artisans, combining their creativity and expertise to create stunning, one-of-a-kind designs. Whether you're seeking intricate geometric patterns, realistic portraits, vibrant watercolors, or delicate fine lines,.
        </p>

      </ItemText>






      <ItemText>
        <h1 data-aos="fade-down">
          EXCELLENCE
        </h1>
        <p data-aos="fade-left">
          Tattooing isn't our job, it's our passion. When we're not tattooing you'll usually find us studying, practicing, and improving so that every piece of art we create is better than the last. We appreciate the trust each of our clients gives to us, and we will always work hard to reward that trust.
        </p>

      </ItemText>

    </Wrap>


  )
}

export default AboutUs
const Wrap = styled.div`

padding-left:70px;
padding-right:70px;
    margin-bottom:40px;
    text-align: center;
    align-items: center;
      margin-left: auto;
      margin-right: auto;
    
`



const ItemText = styled.div`
width:100%;
color: #000;
font-size: 50px;
padding-top:50px;
padding-bottom:100px;
margin-bottom:40px;
text-align: center;
align-items: center;
margin-left: auto;
margin-right: auto;
h1{
    font-family: 'Dancing Script', cursive;
  color:#000;
  font-size:45px;
  line-height: 1.2;
}
p{
  font-weight: normal;
  width:95%;
  color:#000;
  font-size:22px;
  line-height: 1.7;
  font-family: 'Dancing Script', cursive;
  margin-bottom:20px;
  margin-top:15px;
}

a{
  margin-top:10px;
 color:#000;
  padding: 8px 25px 9px 25px;
  border: 2px  solid #fff;
  border-radius: 5px;
  font-size: 23px;
  transition: 0.3s;
  border: 2px  solid #000;
}
a:hover{
  border: 2px  solid #000;
  background: #D0A257;
}
@media screen and (max-width: 768px) {
font-size: 50px;
padding-top: 20vh;
margin-left:10px;
  h1{
    font-size:25px;
  }
  h3{ 
    font-size:13px;
  }
  a{
     padding: 8px 25px 9px 25px;
     font-size: 16px;
   }
   p{
    width:100%;
   }
}


`


