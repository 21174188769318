import React from 'react'
import styled from 'styled-components'
import Awardimg1 from '../../assets/images/LION.jpg'
import Awardimg2 from '../../assets/images/lihtf.jpg'
import Awardimg3 from '../../assets/images/warr.jpg'

function MidImages() {
    return (
        <Container>
            <img src={Awardimg1} alt='' />
            <img src={Awardimg2} alt='' />
            <img src={Awardimg3} alt='' />
        </Container>
    )
}

export default MidImages
const Container = styled.div`
overflow: hidden;
margin-top:60px;
display:flex;
flex-wrap:wrap;
justify-content: space-around;
padding:30px;
img{
margin-top:10px;
    width:280px;
    height:400px;
}
`