
import React, { useEffect } from 'react'
import slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { styled } from 'styled-components'


import img1 from '../../assets/shop/MainShop.jpg'
import img2 from '../../assets/shop/shop1.jpg'
import img3 from '../../assets/shop/shop2.jpg'
import { Link } from 'react-router-dom'

function ShortAbout() {


  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 400,
      easing: 'ease-in-sine',
      delay: 100,
    });
  }, [])

  let settings = {
    className: "center",
    infinite: true,
    arrows: true,
    autoplay: true,
    centerPadding: "60px",
    slidesToShow: 1,
    interval: 12000,

    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,

          className: "center",
          centerPadding: "60px",
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,

          className: "center",
          centerPadding: "60px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          className: "center",
          centerPadding: "60px",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          className: "center",

          centerPadding: "60px",
        },
      },
    ],
  };


  return (

    <Wrap>
      <LeftSide>
        <Carousel {...settings}>
          <RightImage>
            <img src={img1} alt='' title='' />
          </RightImage>
          <RightImage>
            <img src={img2} alt='' title='' />
          </RightImage>

          <RightImage>
            <img src={img3} alt='' title='' />
          </RightImage>

        </Carousel>
      </LeftSide>

      <ItemText>
        <h1 >
          About Us
        </h1>
        <p  >
          TATTOO TRIPPERS  is one of the best tattoo and piercing studios in Goa, Our artists are highly experienced with many years of Tattooing under their belts, and our space is clinically clean to provide the safest environment for your procedure it being a Tattoo
          We understand that every client is unique, and we strive to provide a comfortable and welcoming environment where you can feel at ease throughout the entire tattooing process. From the initial consultation to the final result, we are dedicated to providing you with a positive and satisfying experience.

          We commit to excellence in every single work that leaves our shop, doesn’t matter what is it.
        </p>
        <Link to="/about" data-aos="fade-up">Read More</Link>
      </ItemText>


    </Wrap>

  )
}

export default ShortAbout
const Carousel = styled(slider)`

outline: none;
`;


const LeftSide = styled.div`
width:50%;
@media screen and (max-width: 768px){
  width:100%;
}

`
const Wrap = styled.div`
width:100%;
display:flex;
height:100%;
margin-top:60px;
@media screen and (max-width: 768px){
  flex-wrap:wrap;
height:100%;
margin-top:0px;
}

`


const RightImage = styled.div`
width:100%;
padding:30px;
outline: none;

img{
  width: 370px;
  height:370px;
  border-radius:10px;
}
@media screen and (max-width: 768px){
  img{
    width: 290px;
    height:290px;
  }
}
`

const ItemText = styled.div`
width:100%;
text-align: left;
color: #000;
font-size: 50px;

margin-left:30px;

h1{
    font-family: 'Dancing Script', cursive;
  color:#000;
  font-size:45px;
 
  line-height: 1.2;
}
p{
  font-weight: normal;
  width:95%;
  line-height: 1.7;
  color:#000;
  font-size:19px;
  font-family: 'Dancing Script', cursive;
  margin-bottom:20px;
  margin-top:15px;
}

a{
  margin-top:10px;
 color:#000;
  padding: 8px 25px 9px 25px;
  border: 2px  solid #fff;
  border-radius: 5px;
  font-size: 23px;
  transition: 0.3s;
  border: 2px  solid #000;
}
a:hover{
  
  border: 2px  solid #000;
  background: #D0A257;
}
@media screen and (max-width: 768px) {
text-align:center;
  font-size: 50px;
padding-top: 5vh;
margin-left:10px;
  h1{
  
    font-size:45px;
    
  }
  h3{ 
   
    font-size:13px;

  }
  a{
  
    
     padding: 8px 25px 9px 25px;
     
     font-size: 16px;
    
    
   }
}


`


