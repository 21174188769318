import React from 'react'
import { styled } from 'styled-components'
import reactangle from '../../assets/images/LION.jpg'
import reactangle2 from '../../assets/images/reactangle2.png'
import reactangle3 from '../../assets/images/KSHR.jpg'
import reactangle4 from '../../assets/images/warr.jpg'
import reactangle5 from '../../assets/images/wold.jpg'
import reactangle6 from '../../assets/images/loug.jpg'
import reactangle7 from '../../assets/images/reactangle3.png'
import reactangle8 from '../../assets/images/reactangle4.png'
import reactangle9 from '../../assets/images/OM2.jpg'
import reactangle10 from '../../assets/images/om.jpg'
import reactangle12 from '../../assets/images/maori.jpg'
import reactangle11 from '../../assets/images/lihtf.jpg'
import reactangle16 from '../../assets/images/Untitled-1.jpg'
import reactangle13 from '../../assets/images/flo.jpg'
import reactangle14 from '../../assets/images/loihf.jpg'
import reactangle15 from '../../assets/images/tourtke.jpg'
import Circull from '../../assets/images/header1.png'
import circul2 from '../../assets/images/circul2.png'
import circul3 from '../../assets/images/header2.png'
import circul4 from '../../assets/images/circul1.png'

function ImageOverlay() {
    return (
        <Container>
            <Circul>
                <img src={Circull} alt='Gallery Images'></img>
            </Circul>
            <Circul>
                <img src={circul2} alt='Gallery Images'></img>
            </Circul>
            <Circul>
                <img src={circul3} alt='Gallery Images'></img>
            </Circul>
            <Circul>
                <img src={circul4} alt='Gallery Images'></img>
            </Circul>
            <Image>
                <img src={reactangle} alt='Gallery Images'></img>
            </Image>

            <Image>
                <img src={reactangle2} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle3} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle4} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle16} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle13} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle14} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle15} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle5} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle6} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle7} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle8} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle9} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle10} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle11} alt='Gallery Images'></img>
            </Image>
            <Image>
                <img src={reactangle12} alt='Gallery Images'></img>
            </Image>

        </Container>
    )
}

export default ImageOverlay

const Container = styled.div`
display:flex;
flex-wrap:wrap;
justify-content:space-around;
`

const Circul = styled.div`
margin-bottom:60px;
margin-left:5px;
img{

   
    width:250px;
height:250px;
}

&:hover{



    img{
  
        width:260px;
    height:250px;
    }
}
`
const Image = styled.div`
object-fit: cover;
border-radius: 25px;
margin:5px;
img{
    border-radius: 15px;
    width:250px;
height:400px;
}
&:hover{


    
    img{
  
        width:250px;
    height:400px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
}
`