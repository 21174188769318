import React from 'react'
import styled from 'styled-components'
import img1 from '../../assets/artist/cassie ppppp.jpg'
import Twitter from '../../assets/images/twitter.svg'
import Instagram from '../../assets/images/Instagram.svg'
import Youtube from '../../assets/images/Youtube.svg'

import HomeShow from '../Pricing/HomeShow'
import { Link } from 'react-router-dom'
import reactangle from '../../assets/piercing/KHB.jpg'
import reactangle2 from '../../assets/piercing/PIERCING.jpg ADH.jpg'
import reactangle3 from '../../assets/piercing/,KHGDFBB.jpg'

function CassyArtisCard() {
    return (
        <>
            <Conatiner>
                <LeftSide>
                    <RightImage>
                        <img src={img1} alt='' title='' />
                        <h1>Cassie</h1>
                        <SocialLink>

                            <a target='blank' href=" https://twitter.com/linkosm_in"><li> <img src={Twitter} alt='Linkosm Twitter Logo' title='Linkosm Twitter Logo' /></li></a>
                            <a target='blank' href="https://www.instagram.com/linkosm_in/"><li> <img src={Instagram} alt='Linkosm Instagram Logo' title='Linkosm Instagram Logo' /></li></a>
                            <a target='blank' href="https://www.youtube.com/@linkosm"> <li> <img src={Youtube} alt='Linkosm YouTube Logo' title='Linkosm YouTube Logo' /></li></a>


                        </SocialLink>
                    </RightImage>
                </LeftSide>

                <RightSide>

                    <p>"The fact that art created this life for me is absolutely beautiful. <br />
                        I always knew I was different it wasn't easy to fit into society I had a different path and it helped me to fulfill my soul. <br />
                        The first time I ever saw what a tattoo was like was when I was 8 years old. I saw it on a TV channel, and it was fascinating to me, the first experience I got to explore a live version of a tattoo was when I saw my aunty get one when I was 14 years and by the age of 15 I had my first tattoo and it never stopped for me, I loved Piercings as well and as time progressed I was exposed to widely to the culture of art.
                        "
                    </p>
                    <br />
                    <p>"My Body is My Temple and I would love to Adorn it with art that holds so near and dear to me. Tattoos, Piercing, and Body Modification showed me a path of beautiful energy and enlightenment. Helped me find my way and made me understand where I belong and it's ok to be different because it's something meant for me. I over time became an artist because of my love, passion, and happiness towards this world. The pleasure and joy of carrying art on my body based on an idea and imaginative creativity is unique and pure. To me when I work I feel my art is on someone and I want that energy to shine and travel and explore and have a little bit of me to carry out a beautiful journey through a person's life. "</p>
                    <p>To reach this point I haven't done it alone and I would love to express my gratitude and respect for each of these beautiful people who have made this journey possible because sometimes people forget that it takes two hands to clap. <br />
                        To my experience and understanding, I am blessed that I have the pleasure to meet and also work with beautiful artists, it goes without saying they do deserve all the credit Emkay Jones from Kal Mudra
                    </p>
                    <br />

                    <p>" Tattoo Studio captured my eye with his amazing style of art which is unique, and we have a piece that is in progress on my neck and chest. Another Beautiful realistic artist as everyone knows is Kumar Shady from black beetle tattoo studio who has done amazing pieces on my face and calf as well as traditional African culture. At one of the first conventions I attended, I sat with Yash from Yash Tattoo Studio a wonderful artist who did minimalist portraits on my leg and forearm, A Perfect Queen Adi from Hawk Tattoo working on my back for color Realistic Art, and Sabrish from skin deep tattoo studio who made a beautiful tribal woman on my thigh for another convention. Zombie from a piercing artist who's been a great part of my Piercing lifestyle as well.  Shiva is an amazing artist who's given me an abundance of knowledge on Scarification.</p>
                    <br />
                    <p>The most amazing body modification of my eyes something I desired longer than I can fathom and finally to hold the pleasure of being India's first female with an eyeball tattoo has been done by a Nepal artist who's well experienced and adequate Anthony Sines. <br />
                        To me, this isn't just something that happens every day.  I have the most high regard for these people who have played a massive role in my life. We together created a project that is still in process and has no end because art isn't something that I have done alone I had each one of these amazing people and artists with me.
                        "</p>

                    <br />
                    <p>It took time to establish and create what we pictured and imagined. Hours of Exploring, explanations, creation, and output of an idea we shared. <br />
                        To achieve this goal of enhancing my body wouldn't have been possible without these talented beings.
                    </p>
                </RightSide>

            </Conatiner>
            <BottomContainer>

            </BottomContainer>
            <HomeShow
                img1={reactangle}
                img2={reactangle2}
                img3={reactangle3}
            />
            <Buttons>   <Link to="/gallery">VIEW GALLERY</Link></Buttons>

        </>
    )
}

export default CassyArtisCard

const BottomContainer = styled.div`
padding:30px;
p{
    color:#000;
    font-size:18px;
}
`
const SocialLink = styled.div`
padding-left:0px;
display:flex;
flex-wrap:no-wrap;

li{
    img{
        width:55px;
        height:55px;
       padding:15px;
       cursor: pointer;
      
    }
    &hover{
        color:#fff;
       }
}

@media screen and (max-width: 768px)_
li{
    a{
        margin-top:30px;
    }
}
`

const Conatiner = styled.div`
display:flex;
@media screen and (max-width: 768px){
    flex-wrap:wrap;
}

p{
    color:#000;
    font-size:18px;
}
`

const RightSide = styled.div`
padding-top:80px;

h1{
    padding-left:10px;
    color:#000;
    Font-size:40px;
}
@media screen and (max-width: 768px){
    padding-top:10px;
    padding:10px;
}
`
const LeftSide = styled.div`


`


const RightImage = styled.div`
width:100%;
padding:30px;
outline: none;
border-radius: 50%;
margin-top:110px;
img{
  width: 300px;
  height:300px;
  border-radius: 50%;
}
@media screen and (max-width: 768px){
  img{
    width: 290px;
    height:290px;
  }
}
h1{
    padding-left:10px;
    color:#000;
    Font-size:30px;
}
`

const Buttons = styled.div`
padding-top:50px;
margin-bottom:40px;
text-align: center;
align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

a{
        
    color:#000;
     padding: 8px 25px 9px 25px;
     border: 2px  solid #000;
     border-radius: 5px;
     font-size: 23px;
     transition: 0.3s;
    
   }
   a:hover{
     
     border: 2px  solid #fff;
     background: #D0A257;
   }
`