import React from 'react'
import ContactHead from '../../Components/ContactsComponents/ContactHead'
import styled from 'styled-components'
import Form from './Form'
import { Helmet } from 'react-helmet-async';

function ContactScreen() {
    window.scrollTo(0, 0);
    return (
        <>

            <Helmet>
                <title>START A CONSULTATION | TattooTrippers  </title>
                <meta name="description" content="Transform your body into a canvas of extraordinary artistry with the absolute best tattoo artist near you! Our skilled and passionate tattoo professionals are wizards of ink, dedicated to turning your wildest ideas into jaw-dropping masterpieces. From intricate details to bold statements, our artists redefine the art of self-expression. Unleash your creativity and make a statement with the best—where every tattoo tells a unique story, and your body becomes a gallery of unparalleled craftsmanship. Elevate your ink game, because ordinary is just not our style!" />

                <link rel="canonical" href="https://www.tattootrippers.com/book-now" />
                <meta property="og:image" content="https://www.tattootrippers.com/static/media/tattootripermain.png.42f351b21d9937ece453.jpg" />
                <meta property="og:url" content="https://www.tattootrippers.com/book-now" />
                <meta property="og:description" content="Transform your body into a canvas of extraordinary artistry with the absolute best tattoo artist near you! Our skilled and passionate tattoo professionals are wizards of ink, dedicated to turning your wildest ideas into jaw-dropping masterpieces. From intricate details to bold statements, our artists redefine the art of self-expression. Unleash your creativity and make a statement with the best—where every tattoo tells a unique story, and your body becomes a gallery of unparalleled craftsmanship. Elevate your ink game, because ordinary is just not our style!" />
                <meta property="og:title" content="Best Tattoo Studio in Goa / India / Arambol |  TattooTrippers | Best tattoo studio in arambol " />
                <meta property="og:type" content="website" />
                <meta property='og:image:width' content=' 1200'></meta>
                <meta property='og:image:height' content=' 722'></meta>
            </Helmet>
            <ContactHead title="START A CONSULTATION" />
            <Text>
                <p >
                    Our vision is to deliver the best art, customer service, and experience possible. The professional artists here at TATTOO TRIPPERS are passionate about their craft. We offer free consultations to help you put together the art you’ve always wanted.



                </p>
                <li >
                    <p><strong>Email : Tattootrippers@gmail.com</strong></p>
                </li>
                <li>
                    <p><strong>Phone:  +91 8982703510</strong></p>
                </li>
            </Text>
            <Heading>
                <h3>
                    Please complete the form below
                </h3>
            </Heading>
            <Form />

        </>
    )
}

export default ContactScreen

const Text = styled.div`
color: #a3a3a3;
font-family: proxima-nova;
font-style: normal;
font-weight: 300;
font-size: 14px;
letter-spacing: .015em;
line-height: 1.8em;

padding-top: 100px;
padding-bottom: 50px;
padding-left: 150px;
padding-right: 150px;
li{
    color:#000;
    margin-top:30px;
   
}
@media screen and (max-width: 768px){
    padding-top: 70px;
padding-bottom: 80px;
padding-left: 20px;
padding-right: 20px;
}
`
const Heading = styled.div`
font-family: "Source Sans Pro",sans-serif;
font-size: 18px;
font-weight: 400;
text-transform: uppercase;
letter-spacing: 1px;
line-height: 1.2em;
font-family: proxima-nova;
font-size: 22px;
font-weight: 300;
text-transform: none;
font-style: normal;
letter-spacing: .015em;
text-decoration: none;
line-height: 1.8em;
padding-left: 150px;
h3{
  
    color: #a7861b;
}

@media screen and (max-width: 768px){
    padding-left: 30px;
}
`