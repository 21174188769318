import React from 'react'
import styled from 'styled-components'
import tattootripertext from '../../assets/images/tattootripers2.png'

import { Link } from 'react-router-dom'
import TopHead from './TopHead'
import BurgerIcon from './BurgerIcon'
function Header(props) {
    return (
        <Container>
            <Logos>
                <Link to="/"> <img src={props.img} alt='TattooTrippers' className='mainlogo' title='TattooTrippers Official Logo' /></Link>
            </Logos>
            <TopHead />
            <BurgerIcon />
        </Container>
    )
}

export default Header
const Container = styled.div`
height:70px;
weight:100%;
align-item: center;
display: flex;
justify-content:space-between;
top: 0;
left: 0;
right: 0;
z-index: 1;
opacity: 1;
p{
    color:#000;
}

@media screen and (max-width: 768px){
    justify-content:space-between;
}


`




const Logos = styled.div`


margin-left:60px;
.mainlogo{
    display: none;
    width:170px;
    height:25px;
    cursor: pointer;
   }

    
    @media screen and (max-width: 768px){
       margin-left:20px;
      
       .mainlogo{
        display: flex;
        width:170px;
        height:25px;
        cursor: pointer;
       }

    }
    
`


